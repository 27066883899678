<template>
  <v-container class="px-8 sw-pt-10" fluid>
    <transition name="fade">
      <div v-if="isDataLoaded">
        <v-card flat max-width="800" class="mx-auto" :style="cssVariables">
          <v-row class="no-gutters">
            <v-col cols="12">
              <template v-if="inProgress">
                <div class="user-info">
                  <div class="sw-page-title">
                    {{ pageTitle }}
                  </div>

                  <div
                    v-if="
                      plugin.attributes &&
                        plugin.attributes.signup_answers_body_text
                    "
                    v-html="plugin.attributes.signup_answers_body_text"
                    class="mb-5"
                  ></div>

                  <div class="mb-4">
                    <h5>{{ user.first_name }} {{ user.last_name }}</h5>
                    <p>
                      <template v-if="user.phone_code && user.phone">
                        <a :href="`tel:+${user.phone_code}${user.phone}`">
                          +{{ user.phone_code }} {{ user.phone }}
                        </a>
                        <br />
                      </template>
                      <a :href="`mailto:${user.email}`">
                        {{ user.email }}
                      </a>
                    </p>
                  </div>

                  <template v-if="isGuestAttributeTagsEnabled">
                    <div
                      v-for="(group, gi) in groupedQuestions"
                      :key="`group-${gi}`"
                      class="pb-5"
                    >
                      <div
                        v-if="!group.hide_name || !group.hide_description"
                        class="mb-4"
                      >
                        <template v-if="!group.hide_name">
                          <h4>
                            <strong>{{ group.name }}</strong>
                          </h4>
                        </template>
                        <template v-if="!group.hide_description">
                          <p>{{ group.description }}</p>
                        </template>
                      </div>
                      <template
                        v-if="group.attributes && group.attributes.length > 0"
                      >
                        <template v-for="(question, index) in group.attributes">
                          <div
                            v-if="!question.hidden"
                            :key="`question-${index}`"
                            class="mb-4"
                          >
                            <template v-if="question.answer">
                              <template v-if="question.type === 'options'">
                                <template v-if="question.answer.length > 0">
                                  <h5>{{ question.name }}</h5>
                                  <p>
                                    <template
                                      v-for="(answer, index) in question.answer"
                                    >
                                      <div :key="`ans-${index}`">
                                        {{ answer.name }}
                                      </div>
                                    </template>
                                  </p>
                                </template>
                              </template>

                              <template v-if="question.type === 'datetime'">
                                <h5>{{ question.name }}</h5>
                                <p>
                                  {{ getFormatedDate(question.answer) }}
                                </p>
                              </template>

                              <template v-if="question.type === 'text'">
                                <h5>{{ question.name }}</h5>
                                <nl2br tag="p" :text="question.answer" />
                              </template>

                              <template v-if="question.type === 'varchar'">
                                <h5>{{ question.name }}</h5>
                                <p>{{ question.answer }}</p>
                              </template>
                            </template>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>

                  <template
                    v-if="!isGuestAttributeTagsEnabled && questions.length > 0"
                  >
                    <template v-for="(question, index) in questions">
                      <div
                        v-if="!question.hidden"
                        :key="`question-${index}`"
                        class="mb-4"
                      >
                        <template v-if="question.answer">
                          <template v-if="question.type === 'options'">
                            <template v-if="question.answer.length > 0">
                              <h5>{{ question.name }}</h5>
                              <p>
                                <template
                                  v-for="(answer, index) in question.answer"
                                >
                                  <div :key="`ans-${index}`">
                                    {{ answer.name }}
                                  </div>
                                </template>
                              </p>
                            </template>
                          </template>

                          <template v-if="question.type === 'datetime'">
                            <h5>{{ question.name }}</h5>
                            <p>
                              {{ getFormatedDate(question.answer) }}
                            </p>
                          </template>

                          <template v-if="question.type === 'text'">
                            <h5>{{ question.name }}</h5>
                            <nl2br tag="p" :text="question.answer" />
                          </template>

                          <template v-if="question.type === 'varchar'">
                            <h5>{{ question.name }}</h5>
                            <p>{{ question.answer }}</p>
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
              <!-- /if inProgress -->
              <template v-else>
                <template v-if="fallbackText">
                  <v-col cols="12" class="text-center">
                    <nl2br
                      tag="span"
                      :text="fallbackText"
                      class="text-h6 font-weight-light"
                    />
                  </v-col>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </transition>
    <AppSnackbar />
  </v-container>
</template>
<script>
import AppSnackbar from "@/components/AppSnackbar.vue";
import moment from "moment";
import EventBus from "@/utils/eventBus";

export default {
  name: "QuestionaryReportPage",
  data: () => ({
    isDataLoaded: false,
    plugin: {},
    category: {},
    questions: [],
    questionTags: [],
    dateFormat: "DD.MM.YYYY",
    inProgress: false,
  }),
  components: {
    AppSnackbar,
  },
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store?.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    groupPluginId() {
      return this.$route?.params?.group_plugin_id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    userToken() {
      return this.$store?.state?.userToken;
    },
    fallbackText() {
      return this.plugin?.attributes?.signup_answers_fallback_text || "";
    },
    isGuestAttributeTagsEnabled() {
      return this.plugin?.attributes?.signup_guests_attribute_tags;
    },
    navigation() {
      return this.$store?.getters?.navigation?.destinations?.find(
        (el) =>
          el.component === "QuestionaryReportPage" &&
          el.group_plugin_id == this.groupPluginId,
      );
    },
    pageTitle() {
      return this.navigation?.name;
    },
    groupedQuestions() {
      return this.questionTags.map((tag) => ({
        ...tag,
        attributes: this.questions.filter((attr) =>
          attr.tags.some((el) => el.id === tag.id),
        ),
      }));
    },
    cssVariables() {
      return {
        "--primary-color": this.gmPrimaryColor,
        "--secondary-color": this.gmSecondaryColor,
      };
    },
  },
  watch: {
    appLanguage: {
      async handler() {
        this.getPlugin();
        await this.listUserAttributeTags();
        await this.listUserAttributes();
      },
    },
  },
  async mounted() {
    await this.autoLogin();
    await this.fetchData();
  },
  methods: {
    async listUserAttributeTags() {
      const params = {
        group_plugin_id: this.groupPluginId,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/tags`,
        { params },
      );

      this.questionTags = response.data.data;
    },
    async getCategory() {
      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        {
          params: {
            group_plugin_id: this.groupPluginId,
            per_page: 1,
          },
        },
      );

      if (!response?.data?.data?.[0]) return;

      this.category = response?.data?.data?.[0];
    },
    async listUserAttributes() {
      this.questions = [];

      const params = {
        category_id: this.category.id,
        lang: this.appLanguage,
        per_page: 10000,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/list_related`,
        { params },
      );

      if (!response?.data?.data) return;

      for (const question of response?.data?.data) {
        const value = response?.data?.meta[question?.slug];

        question.meta_value = value;

        if (["varchar", "datetime", "text"].includes(question.type)) {
          question.answer = value;
        }

        if (question.type === "options" && question?.is_collection) {
          question.answer = question.options
            .filter((option) => value.includes(option.id))
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        }

        if (question.type === "options" && !question?.is_collection) {
          question.answer = question.options
            .filter((option) => value === option.id)
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        }

        this.questions.push(question);
      }

      const meta = response?.data?.meta || {};

      for (const [key, value] of Object.entries(meta)) {
        if (key.includes("reserved_g") && key.includes("_progress")) {
          this.inProgress = value > 0;
        }
      }
    },
    async autoLogin() {
      if (!this.$route.query.token) return;

      const query = Object.assign({}, this.$route.query);

      try {
        const params = {
          token: query.token,
        };

        const response = await this.$http.get(`/auth/login`, { params });

        if (!response?.data?.data?.is_latest_terms_accepted) {
          this.$router.push({
            name: "FrontPage",
            query: { token: this.$route.query.token },
          });

          return;
        }

        this.$store.commit("SET_USER_TOKEN", response.data.data.token);

        this.$router.replace({}).catch(() => {});

        EventBus.$emit("showNotification", {
          type: "success",
          message: this.$vuetify.lang.t("$vuetify.loginSuccessfullyDone"),
        });
      } catch (error) {
        if (error) {
          EventBus.$emit("showNotification", {
            type: "error",
            message: this.$vuetify.lang.t("$vuetify.tokenExpired"),
          });

          this.$router.replace({}).catch(() => {});
        }
      }
    },
    async getPlugin() {
      const params = {
        with_attributes: 1,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/plugins/${this.groupPluginId}`,
        { params },
      );

      this.plugin = response?.data?.data;
    },
    getDateFormat() {
      this.$http.get(`/settings/public/global.date_format`).then((res) => {
        this.dateFormat = res.data.data.value;
      });
    },
    async fetchData() {
      if (!this.userToken) return;

      try {
        this.getDateFormat();
        this.getPlugin();
        await this.getCategory();
        await this.listUserAttributeTags();
        await this.listUserAttributes();

        this.isDataLoaded = true;
      } catch (error) {
        if (error) this.isDataLoaded = true;
      }
    },
    getFormatedDate(date) {
      return moment(date)
        .format(this.dateFormat)
        .toString();
    },
  },
};
</script>

<style scoped lang="scss"></style>
